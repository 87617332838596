<template>
  <v-container>
    <vue-snotify></vue-snotify>

    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Registrar empresa</span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              DATOS DE LA EMPRESA:
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              DATOS DEL CLIENTE O DATOS DEL REPRESENTANTE LEGAL:
            </v-stepper-step>

            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="mt-3 mr-3 ml-3">
                  Los campos marcados con (<strong>*</strong>) son obligatorios
                  para el proceso de registro.
                </div>
              <v-row>
               
                <v-col cols="12" md="4">
                  <v-text-field v-model="record_number" :rules="record_numberRules" :counter="20"
                    label="Número de Ficha" prepend-icon="mdi-card-text-outline" placeholder="12345678" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="legal_name"
                    :rules="legal_name_Rules"
                    :counter="190"
                    prepend-icon="mdi-store"
                    label="Razón Social *"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="rut"
                    :rules="rutRules"
                    :counter="10"
                    label="R.U.T *"
                    prepend-icon="mdi-card-text-outline"
                    placeholder="12345678-9"
                    required
                  ></v-text-field>
                </v-col>

               

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="trade_name"
                    :rules="trade_name_Rules"
                    :counter="100"
                    prepend-icon="mdi-store-outline"
                    label="Nombre de Fantasía *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
              <v-text-field v-model="acteco" :rules="trade_name_Rules" :counter="100" prepend-icon="mdi-store-outline"
                label="Actividad económica *" required></v-text-field>
            </v-col>
          
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="address"
                    :rules="address_Rules"
                    :counter="190"
                    prepend-icon="mdi-map-marker-outline"
                    label="Dirección *"
                    required
                  ></v-text-field>
                </v-col>
           
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="giro"
                    :counter="250"
                    prepend-icon="mdi-ballot-recount"
                    label="Giro*"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="phone"
                    :rules="phoneRules"
                    :counter="12"
                    prepend-icon="mdi-deskphone"
                    label="Teléfono 1 *"
                    placeholder="56912345678"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="phone2"
                        :rules="phoneRules2"
                        :counter="12"
                        prepend-icon="mdi-deskphone"
                        label="Teléfono 2"
                        placeholder="56912345678"
                      ></v-text-field>
                    </template>
                    <span>El Teléfono 2 es opcional</span>
                  </v-tooltip>
                </v-col>

                <v-col cols="12" md="4">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="phone3"
                        :rules="phoneRules3"
                        :counter="12"
                        prepend-icon="mdi-deskphone"
                        label="Teléfono 3"
                        placeholder="56912345678"
                      ></v-text-field>
                    </template>
                    <span>El Teléfono 3 es opcional</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="mail"
                    :rules="emailRules"
                    placeholder="usuario@correo.com"
                    prepend-icon="mdi-at"
                    label="Correo electrónico *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="password"
                    type="password"
                    prepend-icon="mdi-form-textbox-password"
                    label="Contraseña *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="sii_key"
                        :rules="sii_keyRules"
                        prepend-icon="mdi-key"
                        label="Clave del S.I.I"
                      ></v-text-field>
                    </template>
                    <span>La Clave del S.I.I es opcional</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <br /><br />
              <v-divider></v-divider> <br />
              <v-row>
                <v-col cols="12" lg="8"></v-col>
                <v-col cols="12" lg="4">
                  <v-btn
                    color="primary"
                    class="ma-2"
                    outlined
                    elevation="3"
                    @click="cancel"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                    <span class="ml-1">Cancelar</span>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    color="primary"
                    elevation="3"
                    outlined
                    @click="e1 = 2"
                  >
                    <v-icon>mdi-arrow-right-circle</v-icon>
                    <span class="ml-1">Siguiente</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row>
                <div class="mt-3 mr-3 ml-3">
                  Los campos marcados con (<strong>*</strong>) son obligatorios
                  para el proceso de registro.
                </div>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="legal_representative"
                    :rules="name_Rules"
                    :counter="190"
                    prepend-icon="mdi-account"
                    label="Nombre del representante legal*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="rut_legal_representative"
                    :rules="rut_legal_representativeRules"
                    :counter="10"
                    label="R.U.T del representante legal*"
                    placeholder="12345678-9"
                    prepend-icon="mdi-card-account-details"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="address_legal_representative"
                    :rules="address_Rules"
                    :counter="190"
                    prepend-icon="mdi-map-marker-outline"
                    label="Dirección del representante legal*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="comune"
                    :rules="comuneRules"
                    :counter="100"
                    prepend-icon="mdi-map-marker-outline"
                    label="Comuna del representante legal*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="phone_legal_representative"
                    :rules="phoneRules"
                    :counter="12"
                    prepend-icon="mdi-deskphone"
                    label="Teléfono 1 del representante legal*"
                    placeholder="56912345678"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="phone2_legal_representative"
                        :rules="phoneRules2"
                        :counter="12"
                        prepend-icon="mdi-deskphone"
                        label="Teléfono 2 del representante legal"
                        placeholder="56912345678"
                      ></v-text-field>
                    </template>
                    <span>El Teléfono 2 es opcional</span>
                  </v-tooltip>
                </v-col>

                <v-col cols="12" md="4">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="phone3_legal_representative"
                        :rules="phoneRules2"
                        :counter="12"
                        prepend-icon="mdi-deskphone"
                        label="Teléfono 3 del representante legal"
                        placeholder="56912345678"
                      ></v-text-field>
                    </template>
                    <span>El Teléfono 3 es opcional</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="email_legal_representative"
                    :rules="emailRules"
                    prepend-icon="mdi-at"
                    label="Correo electrónico del representante legal*"
                    placeholder="usuario@correo.com"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="accountand"
                        :rules="name_Rules"
                        :counter="190"
                        prepend-icon="mdi-account"
                        label="Nombre del contador"
                      ></v-text-field>
                    </template>
                    <span>El Nombre del contador es opcional</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" md="4">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="phone_accountand"
                        :rules="phoneRules"
                        :counter="12"
                        prepend-icon="mdi-deskphone"
                        placeholder="56912345678"
                        label="Teléfono del contador"
                      ></v-text-field>
                    </template>
                    <span>El Teléfono del contador es opcional</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" md="12">
                  <v-tooltip top class="mt-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="email_accountand"
                        :rules="emailRules"
                        prepend-icon="mdi-at"
                        label="Correo electrónico del contador"
                        placeholder="usuario@correo.com"
                      ></v-text-field>
                    </template>
                    <span>El Correo electrónico del contador es opcional</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <br /><br />
              <v-divider></v-divider> <br />
              <v-row>
                <v-col cols="12" lg="5"></v-col>
                <v-col cols="12" lg="2">
                  <v-btn
                    color="primary"
                    class="mr-4 ml-4"
                    outlined
                    @click="e1 = 1"
                  >
                    <v-icon>mdi-arrow-left-circle</v-icon>
                    <span class=""> Volver</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" lg="1"> </v-col>
                <v-col cols="12" lg="4">
                  <v-btn color="primary" class="mr-2" outlined @click="cancel">
                    <v-icon>mdi-close-circle</v-icon>
                    <span class="ml-1">Cancelar</span>
                  </v-btn>
                  <v-btn color="primary" class="" outlined @click="create">
                    <v-icon>mdi-check-circle</v-icon
                    ><span class="ml-1">Crear</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    //Modelo
    legal_name: "",
    trade_name: "",
    rut: "",
    record_number: "",
    address: "",
    acteco: "",
    mail: "",
    password: "",
    giro: "",
    phone: "",
    phone2: "",
    phone3: "",
    sii_key: "",
    legal_representative: "",
    rut_legal_representative: "",
    address_legal_representative: "",
    comune: "",
    phone_legal_representative: "",
    phone2_legal_representative: "",
    phone3_legal_representative: "",
    email_legal_representative: "",
    accountand: "",
    phone_accountand: "",
    email_accountand: "",
    firm_key: "",
    errors:[],

    e1: 1,

    legal_name_Rules: [
      v => !!v || 'El campo Razón social es requerido'
    ],
    rutRules: [
      v => !!v || 'El RUT es requerido',
      v => (/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(v)) || 'El campo RUT no es válido',
    ],
    record_numberRules: [
      v => !!v || 'El campo número de ficha es requerido',
      v => (v.length < 20) || 'El campo número de ficha no debe exceder 20 caracteres',
    ],
    trade_name_Rules: [
      v => !!v || 'El campo nombre de fantasía es requerido',
      v => (v.length < 190) || 'El campo número de cliente no debe exceder 190 caracteres',
    ],
    address_Rules: [
      v => !!v || 'El campo dirección es requerido',
      v => (v.length < 190) || 'El campo dirección no debe exceder 190 caracteres',
    ],
    actecoRules: [
      v => !!v || 'El campo actividad económica es requerido',
      v => (v.length < 190) || 'El campo dirección no debe exceder 100 caracteres',
    ],
    giroRules: [
      v => !!v || 'El campo giro económica es requerido',
      v => (v.length < 190) || 'El campo giro no debe exceder 10 caracteres',
    ],
    sii_keyRules: [
      v => !!v || 'El campo Clave S.I.I  es requerido',
      v => (v.length < 190) || 'El campo Clave S.I.I  no debe exceder 10 caracteres',
    ],
    name_Rules: [
      v => !!v || 'El campo nombre de representante legal es requerido',
      v => (v.length < 50) || 'El campo nombre de representante legal  no debe exceder  50 caracteres',
    ],
    name_accountand_Rules: [
      v => !!v || 'El campo nombre de contador  es requerido',
      v => (v.length < 50) || 'El campo nombre de contador  no debe exceder  50 caracteres',
    ],

    descriptionRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],

    priceRules: [
      (v) =>
        (v && v.length <= 10) || "El campo no debe exceder de 10 caracteres",
    ],
    emailRules: [
      v => !!v || 'El correo es requerido',
      v => /.+@.+\..+/.test(v) || 'El campo correo no es válido',
    ],
    phoneRules: [
      v => !!v || 'El número de teléfono es requerido',
      v => /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/.test(v) || 'El campo teléfono no es válido',
      ],
      phoneRules2: [
      
      v => (/^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/.test(v) ||  /^\s*$/.test(v) ) || 'El campo teléfono no es válido',
      ],
      phoneRules3: [
      //v => /^\s*$/.test(v) || 'El campo no es válido',
      v => (/^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/.test(v) ||  /^\s*$/.test(v) ) || 'El campo teléfono no es válido',
      ],
  }),

  mounted() {
    console.log("pasa al crear empresa");
  },
  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "customers" }), 10);
    },

    create() {
      let request = {
        legal_name: this.legal_name,
        trade_name: this.trade_name,
        rut: this.rut,
        record_number: this.record_number,
        address: this.address,
        mail: this.mail,
        password: this.password,
        giro: this.giro,
        phone: this.phone,
        phone2: this.phone2,
        phone3: this.phone3,
        sii_key: this.sii_key,
        legal_representative: this.legal_representative,
        rut_legal_representative: this.rut_legal_representative,
        address_legal_representative: this.address_legal_representative,
        comune: this.comune,
        phone_legal_representative: this.phone_legal_representative,
        phone2_legal_representative: this.phone2_legal_representative,
        phone3_legal_representative: this.phone3_legal_representative,
        email_legal_representative: this.email_legal_representative,
        accountand: this.accountand,
        phone_accountand: this.phone_accountand,
        email_accountand: this.email_accountand,
        firm_key: this.firm_key,
      };

      console.log(request);
      axios
        .post("/save-customer", request)
        .then((response) => {
          this.displayNotification(
            "success",
            "Éxito",
            "Cliente insertado correctamente"
          );
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "customers" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo agregar el cliente"
          );
        });
    },
  },
};
</script>